var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      staticClass: "pa-0 mt-5 justify-center company-page",
      attrs: { row: "", wrap: "" },
    },
    [
      _c(
        "v-flex",
        {
          staticClass: "ma-0 mt-5",
          attrs: { xs12: "", md9: "", "justify-center": "" },
        },
        [
          !_vm.isAnswered
            ? _c(
                "v-card",
                { staticClass: "card", attrs: { flat: "" } },
                [
                  !_vm.updated
                    ? _c(
                        "v-layout",
                        {
                          staticClass: "pa-0 justify-center",
                          attrs: { row: "", wrap: "" },
                        },
                        [
                          _c("p", {
                            staticClass: "subtitle pl-10 pt-5 pb-0",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("general.stayinguser.intro")
                              ),
                            },
                          }),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "justify-center": "" } },
                            [
                              _vm.showListItems
                                ? _c("staying-user-list", {
                                    attrs: { company: _vm.company },
                                    on: { updated: _vm.toggle },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.updated
                    ? _c(
                        "v-layout",
                        {
                          staticClass: "pa-0",
                          attrs: { row: "", wrap: "", "justify-center": "" },
                        },
                        [
                          _c("p", {
                            staticClass: "subtitle pl-10 pt-5 pb-0",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(_vm.company + ".staying.done")
                              ),
                            },
                          }),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                "justify-center": "",
                                "align-center": "",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex justify-center",
                                  staticStyle: { margin: "0 15%" },
                                },
                                [
                                  _c("v-img", {
                                    staticStyle: { margin: "0 auto" },
                                    attrs: {
                                      src: _vm.img,
                                      contain: "",
                                      transition: "scale-transition",
                                      "max-width": "600",
                                      "text-center": "",
                                      "justify-center": "",
                                      flex: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "v-card",
                { staticClass: "card", attrs: { flat: "" } },
                [
                  !_vm.updated
                    ? _c(
                        "v-layout",
                        {
                          staticClass: "pa-0 justify-center",
                          attrs: { row: "", wrap: "" },
                        },
                        [
                          _c("p", {
                            staticClass: "subtitle pl-10 pt-5 pb-0",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("general.stayinguser.intro")
                              ),
                            },
                          }),
                          _c("p", {
                            staticClass: "px-10 pt-5 pb-0 mb-14",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("general.stayinguser.already-payed")
                              ),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }