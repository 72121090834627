var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "payment-page" },
    [
      _c(
        "v-app-bar",
        {
          staticClass: "company--logo",
          attrs: { app: "", color: "transparent", dark: "", flat: "" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _vm.company
                ? _c("v-img", {
                    staticClass: "my-4 mx-5",
                    attrs: {
                      alt: "Upgrade Estate Logo",
                      contain: "",
                      src: _vm.logo,
                      transition: "scale-transition",
                      width: "176px",
                      height: "82px",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("v-spacer"),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }